@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&family=VT323&display=swap');

@font-face {
  font-family: 'NanumGothic-Regular'; /*a name to be used later*/
  src: url('./assets/fonts/NanumGothic-Regular.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'DoHyeon-Regular'; /*a name to be used later*/
  src: url('./assets/fonts/DoHyeon-Regular.ttf'); /*URL to font*/
}

html{
  min-height: 100%;
}


/* body, p, a, ul, li{
  margin : 0;
  padding : 0;
  text-decoration : none;
}

li{
  list-style-type:none;
} */

body {
  margin: 0;
  font-family: 'NanumGothic-Regular';
  color: #333;
  min-height: 100%;
  padding: 20px;
}

.container2{
  max-width:960px;
  margin:10px auto;
  padding:20px;
  color:white;
  background-color: pink;
}

.container3{
  max-width:960px;
  margin:10px auto;
  padding:20px;
  color:black;
  background-color: #eaeeef;
}

.container3 nav{
  color:white;
}



.containerBasic{
  max-width:960px;
  margin:10px auto;
  padding:20px;
  color:black;
}

.containerBasic2{
  max-width:960px;
  margin:10px auto;
  padding:20px;
  color:black;
}

.containerBasic2 a{
  color : black;
}

.notee p{
  color : red;
}

.templates{
  max-width:960px;
  min-height:500px;
  margin:10px auto;
  padding:20px;
  background-color: #b7d3d4;
  color:white;
  text-align: center;
}

.pp{
  position:relative
}

.temp1{
  width:46px;
  height:46px;
  line-height: 46px;
  border-radius: 23px;
  background-color: #0AC2FF;
  position:absolute;
  left:358px;
  top:95px;
}
.temp2{
  width:46px;
  height:46px;
  line-height: 46px;
  border-radius: 23px;
  background-color: #0AC2FF;
  position:absolute;
  left:326px;
  top:172px;
}
.temp3{
  width:30px;
  height:30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #0AC2FF;
  position:absolute;
  left:379px;
  top:220px;
}
.temp4{
  width:46px;
  height:46px;
  line-height: 46px;
  border-radius: 23px;
  background-color: #0AC2FF;
  position:absolute;
  left:414px;
  top: 263px;
}
.temp5{
  width:30px;
  height:30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #0AC2FF;
  position:absolute;
  left:596px;
  top: 106.5px;
}
.temp6{
  width:46px;
  height:46px;
  line-height: 46px;
  border-radius: 23px;
  background-color: #0AC2FF;
  position:absolute;
  left:622px;
  top:154.5px;
}
.temp7{
  width:46px;
  height:46px;
  line-height: 46px;
  border-radius: 23px;
  background-color: #0AC2FF;
  position:absolute;
  left:612px;
  top:229px;
}
.temp8{
  width:30px;
  height:30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #0AC2FF;
  position:absolute;
  left:570px;
  top:266px;
}



.pastjournals{
  background-color: #fff;
  color:#333;
  border-radius: 5px;
  width:94%;
  margin:0 auto;
}

.pastjournals a{
  color:#333;
}

.pastjournals ul{
  padding:5px;
}

.order{
  z-index: 1;
}

.button {
  background : none;
  border : 2px solid gray;
  color : gray;
  padding : 6px 12px;
  border-radius: 20px;
  display : inline-block;
}

.button:hover{
  color : #222;
  background : white;
}

input, textarea {
  background : white;
  padding : 10px 16px;
  border-radius: 20px;
  border : 2px solid #9893D8;
}

.notiIndi{
  text-align: center;
  width: 80%;
  height : 60px;
  padding : 10px auto;
  border : 2px dashed coral;
  border-radius: 10px;
  margin : 10px auto;
}

.white-text{
  color : white;
}

.profileIcon {
  width : 100px;
  height:100px;
}

.smalllist{
  text-align: center;
}

.smalllist p{
  padding:0;
  margin : 0 auto;
  font-size: 16px;
}

.checking{
  text-align: center;
}

a {
  color: rgb(3, 196, 154);
  text-decoration: none;
}
img {
  max-width: 100%;
}

ul{
  list-style-type: none;
}
nav {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom:10px;
}
nav h1 {
  margin-right: auto;
}

textarea {
  width:400px;
  height:100px;
}

/* 여기부터 코드펜  */
.nav {
  display: block;
  font: 13px Helvetica, Tahoma, serif;
  text-transform: uppercase;
  margin: 0; 
  padding: 0;
}

.nav li {
  display: inline-block;
  list-style: none;
}

.nav .button-dropdown {
  position: relative;
}

.nav li a {
  display: block;
  color: #333;
  background-color: #fff;
  padding: 10px 20px;
  text-decoration: none;
}

.nav li a span {
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
  color: #999;
}

.nav li a:hover, .nav li a.dropdown-toggle.active {
  background-color: #289dcc;
  color: #fff;
}

.nav li a:hover span, .nav li a.dropdown-toggle.active span {
  color: #fff;
}

.nav li .dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  text-align: left;
}

.nav li .dropdown-menu.active {
  display: block;
}

.nav li .dropdown-menu a {
  width: 150px;
}



.navIcon{
  font-size: 24px;
  margin:5px;
}

.searchIcon{
  font-size: 24px;
}

.content {
  max-width:960px;
  /* 위에꺼 없으면 원래 버전 */
  display : grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.authContainer{
  background-color: #333;
  min-height: 500px;
  padding:20px;
  margin: 0 auto;
  text-align: center;
  align-content: center;
}
.authContainer form{
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
}


.authContainer input{
  display:block;
  background: rgba(255,255,255,0.05);
  padding: 10px 16px;
  border-radius: 20px;
  border: 2px solid black;
  color: #f2f2f2;
  font-size: 1em;
  width: 30%;
  margin: 10px auto;
}




.authContainer div{
  color:#fff;
  text-align: center;
  align-items: center;
}

.authContainer p{
  color:#fff;
  font-size: 30px;
}

.formContainer{
  text-align: center;
  margin:0 auto;
  align-items: center;
  justify-content: center;
}

.left-side{
  grid-column : 1/2;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #d6c5b0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.right-side{
  grid-column : 2/3;
  background-color: skyblue;
  color:#fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width : 760px){
  .left-side{
    grid-column : 1/3;
    background-color: #d6c5b0;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .right-side{
    grid-column : 1/3;
    background-color: skyblue;
    color:#fff;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* .left-side{
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  float:left;
  background-color: #d6c5b0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.right-side{
  max-width: 49%;
  margin-right: 1%;
  float:left;
  background-color: skyblue;
  color:#fff;
  padding-top: 20px;
  padding-bottom: 20px;
} */

.reports{
  max-width: 980px;
  margin: 0 auto;;
  background-color: #bad6d6;
  padding : 20px;
  color:white;
  text-align: center;
}

.stickers{
  max-width: 980px;
  margin: 0 auto;;
  padding : 20px;
  text-align: center;
}

.stickerGridContainer{
  max-width: 980px;
  display:grid;
  grid-template-columns: repeat(100,1fr);
}

.sticker1{
  grid-column: 65/68;
}

.sticker2{
  grid-column: 24/29;
}

.sticker3{
  grid-column: 71/79;
}

.sticker4{
  grid-column: 50/56;
}

.sticker5{
  grid-column: 30/38;
}

.sticker6{
  grid-column: 45/50;
}

.to-center{
  margin:20px;
}
.bb{
  text-align: center;
}

.bb button{
  background: none;
  border : 2px solid coral;
  border-radius : 20px;
  padding : 10px 16px;
}

.postTrigger{
  background-color: #fff;
  border-radius: 6px;
  padding:10px;
  margin-top:20px;
}

.postTrigger p{
  margin:10px;
  color:#333;
}

.holder{
  height:140px
}

.postTriggerFooter{
  display: flex;
  gap:10px;
  justify-content: flex-end;
}

.smallButton{
  background-color: skyblue;
  padding:4px;
  border-radius: 6px;
  width:40px;
  text-align: center;
}

.smallButton1{
  background-color: skyblue;
  color:#fff;
  padding:4px;
  border-radius: 6px;
  width:100px;
  text-align: center;
}

.card{
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin : 20px auto;
  font-size : 1.2em;
}

.card h2{
  margin-bottom: 0;
}

.card p{
  margin-top: 4px;
}

.alla{
  display: flex;
  justify-content: center;
  gap:20px
}


.product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.offers, .products {
  display: flex;
  gap: 20px;
}
.emotions{
  margin:10px auto;
  display:flex;
  gap:20px;
  font-size: 80px;
  justify-content: center;
}

.activities{
  margin:10px auto;
  display:flex;
  gap:20px;
  font-size: 80px;
  justify-content: center;
}

.container{
  min-height: 100%;
}
.homeImage {
  margin: 0 auto;
  width:300px;
}

.homeIcon {
  width:30px;
  height: 30px;
}

.homeItems {
  display: flex;
  justify-content: center;
  text-decoration: none;
  gap : 10px; 
  margin: 0 auto;
}

.redfamily {
  color: red; 
}
.search-box,.close-icon,.search-wrapper {
  padding: 10px;
  margin-bottom: 0;
}
.search-wrapper {
  width: 100%;
  margin-top: 50px;
  margin: 0 auto;
  justify-content: center;
}
.search-box {
  width: 60%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
  margin-bottom: 0;
}
.search-box:focus {
  box-shadow: 0 0 15px 5px #b0e0ee;
  border: 2px solid #bebede;
}
.close-icon {
  border:1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.search-box:not(:valid) ~ .close-icon {
  display: none;
}



footer{
  margin-top:20px;
  max-width: 980px;
  margin : 20px auto;
  border-top: 1px gray solid;
}

.cancel p {
  color : rgb(100, 100, 241);
  font-size : 14px;
}

.parallel {
  display : flex;
  align-items: center;
}

.profilebutton{
  border-radius: 6px;
  background-color: coral;
  text-align: center;
  color:white;
  width:120px;
  height: 50px;
  line-height: 50px;
  margin : 0 auto;

}

.ii{
  padding:20px;
}



.cominfo p{
  font-size: 14px;
  line-height: 50%;
}



.smallnotifications{
  margin-top:20px;
  background-color: #fff;
  color:#333;
  padding:6px;
  border-radius: 5px;
}



.journal{
  border-bottom:1px solid gray;
  margin-left: 5px;
}

.journal h2{
  font-size: 16px;
}

.journal p{
  font-size: 14px;
}

.stretch{
  height:300px;
}


.modal ul{
  padding: 10px;
}

.bars{
  display:flex;
  gap:5px;
}

.barActive{
  height: 6px;
  border-radius: 6px;
  width:60px;
  background-color: #333;
}

.barInactive{
  height: 6px;
  border-radius: 6px;
  width:60px;
  background-color: #999;
}

.recommsearch{
  color: rgb(3, 196, 154);
  text-decoration: underline;
  font-size: 16px;
  padding-left: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.prompts{
  background-color: #fff;
  color:#333;
  padding:6px 10px;
  padding-left : 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border : 2px dashed skyblue;
}

.query{
  background-color: coral;
  color : #fff;
  padding:6px;
  border-radius: 6px;
  margin:6px;
}

.searchQuery{
  border : 3px dashed coral;
  border-radius: 10px;
  padding:20px;
  text-align: center;
  width:50%;
  margin:0 auto;
}



.categoryContainer{
  display:flex;
  gap:10px;
}



.onecomment {
  display: flex;
  align-items: center;
  gap:10px;
}

.onecomment img{
  width:80px;
  padding:5px;
}

.temp1 p{
  z-index: 1;
  color : black;
}

.bots{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  
}

.bots img{
  width:50px;
  padding:0;
  text-align:center
}


.botIndi{
  padding:0;
  font-size: 12px;
  justify-content: center;
  margin-right: 12px;
  position:relative;
}

.botIndi p{
  padding-top: 0;
  margin: 0 auto;
}

.botnoti{
  background-color: red;
  width:16px;
  height:16px;
  line-height: 16px;
  border-radius: 8px;
  position:absolute;
  color:white;
  font-size: 6px;
  text-align: center;
  justify-items: center;
  left: 34px;
  top:-2px;
}

.profilenav nav{
  background-color: #333;
  float:left;
  position:fixed;
  top:120px;
  left:140px;
  width:8%;
}

.profilenav nav ul{
  padding:0;
}


.profilenav nav li{
  margin: 0 10px;
  padding: 5px;
}

.profilenav nav li:hover{
  color : rgb(3, 196, 154);
}



.indi {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  margin-right: 10px;
  color: #333;
  
}

.indi p{
  margin: 0 auto;
  font-size: 14px;
}



.modal img{
  width:80px;
  padding:5px;
}

.searchResult{
  background-color: #fff;
  width:94%;
  margin: 0 auto;
}

.payment button{
  background-color: orange;
  padding:10px;
  border-radius: 5px;
}



/**/
/* Styles for wrapping the search box */

.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}


/*for modal*/
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  color:#333;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}